import React from 'react'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../Button'
import './styles.css'

export const query = graphql`
  fragment ClubSectionFragment on PagesYamlSections {
    clubLevels {
      Level
      Bottles
      BottlesYear
      Pricing
      Pricing1
      Offerings
      Savings
      Savings2
      Savings3
      Experience
      Experience2
    }
  }
`

const CLUB_DATA = [
  // 'Level',
  '-',
  '# Shipments',
  '-',
  'Bottles',
  'BottlesYear',
  '-',
  'Pricing',
  'Pricing1',
  '-',
  'Offerings',
  '-',
  'Savings',
  'Savings2',
  'Savings3',
  '-',
  // '# Experiences',
  // '-',
  // 'Experience',
  // 'Experience2',
  // '-',
]

// TODO:
// image & CTA for each option, also description
// headings between rows - alignment?
// T&C?

const CTAS = [
  {
    text: 'Join our platinum club',
    href: 'https://store.deerhillvineyards.com/membership-account/membership-checkout/?level=3'
  },
  {
    text: 'Join our gold club',
    href: 'https://store.deerhillvineyards.com/membership-account/membership-checkout/?level=2'
  },
  {
    text: 'Join our silver club',
    href: 'https://store.deerhillvineyards.com/membership-account/membership-checkout/?level=1'
  },
]

function Club(props) {
  const { extraClasses, clubLevels } = props
  return (
    <div className={`Club ${extraClasses}`}>
      <div className="py-4">
        <style>{`
          .club-content-wrapper::after, .club-content-wrapper::before {
            content: '';
            position: absolute;
            right: -1px;
            top: 0;
            bottom: 0;
            display: block;
            background: linear-gradient(90deg, rgba(255,255,255,0.1), rgba(255,255,255,1));
            width: 1.2rem;
            z-index: 1;
          }
          .club-content-wrapper::before {
            right: auto;
            left: 0;
            background: linear-gradient(90deg, rgba(255,255,255,1), rgba(255,255,255,0.1));
          }
          .club-content-container::after {
            content: 'Scroll →';
            position: absolute;
            top: 0;
            right: 1em;
            font-size: 11px;
            opacity: 0.3;
          }
          @media screen {
            .club-table {
              min-width: 300vw;
            }
          }

          @media (min-width: 768px) {
            .club-table {
              min-width: 64rem;
            }
            .club-content-container::after {
              display: none;
            }
            .club-content-wrapper::after, .club-content-wrapper::before {
              display: none;
            }
          }

          @media print {
            .club-content-container::after {
              display: none;
            }
            .club-content-wrapper::after, .club-content-wrapper::before {
              display: none;
            }
          }
        `}</style>
        
        <div className='max-w-5xl m-auto'>
          <div className='club-content mb-2' style={{ pageBreakInside: 'avoid' }}>
            <h2 className='text-center text-2xl font-serif m-3 mb-6'>
              Wine Club Membership
            </h2>
            <div className='club-content-outer-wrapper py-2 md:border border-gray-300 relative'>
              <div className='club-content-wrapper relative left-0 top-0'>
                <div className='club-content-container relative pb-2 overflow-x-scroll md:overflow-x-hidden' style={{
                  scrollSnapAlign: 'start',
                  scrollSnapType: 'x mandatory',
                  // scrollPaddingRight: '5em',
                  // scrollPadding: '5%',
                }}>

                  <table className='club-table md:mr-0' style={{
                    // minWidth: '64rem',
                    // minWidth: '300vw',
                  }}>
                    <thead className='uppercase'>
                      <tr>
                        {clubLevels.map((level, i) => {
                          return (
                            <th
                              key={i}
                              className='pt-3 text-gray-500 tracking-widest'
                              style={{
                                scrollSnapAlign: 'start',
                                scrollSnapType: 'x mandatory',
                                // scrollPaddingRight: '5em',
                                // scrollMarginRight: '5em',
                                width: '33.333%',
                              }}
                            >
                              {level.Level}
                            </th>
                          )
                        })}
                      </tr>
                    </thead>
                    <tbody>
                      {CLUB_DATA.map((item, i) => {
                        if (item === '-') {
                          return (
                            <tr key={i}>
                              <td className='py-6 club-divider' colSpan='3'>
                                <hr />
                              </td>
                            </tr>
                          )
                        }
                        if (item.indexOf('#') === 0) {
                          const text = item.replace('#', '').trim()
                          console.log(text)
                          return (
                            <tr key={i}>
                              <td className='px-4 font-bold text-gray-400 md:text-center' colSpan='3'>
                                <div
                                  className='inline-block'
                                  style={{
                                    position: 'sticky',
                                    left: '1em',
                                  }}
                                  >
                                  {text}
                                </div>
                              </td>
                            </tr>
                          )
                        }
                        return (
                          <tr key={i} className='p-'>
                            {clubLevels.map((level, ii) => {
                              return (
                                <td
                                  key={`${i}-${ii}`}
                                  className='p-1 px-4'
                                >
                                  {level[item]}
                                </td>
                              )
                            })}
                          </tr>
                        )
                      })}

                      <tr className='cta-row'>
                        {CTAS.map((cta, i) => {
                          return (
                            <td
                              key={`${i}`}
                              className='text-center p-1 px-4'
                            >
                              <Button href={cta.href}>
                                {cta.text}
                              </Button>
                            </td>
                          )
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className='club-qr-code opacity-0 mt-1'>
              <div className='text-center mb-2'>Learn more</div>
              <StaticImage
                src="../../images/club-qr-code.png"
                alt="Deer Hill Vineyards club qr code"
                placeholder="blurred"
                layout="fixed"
                width={100}
                style={{ margin: 'auto' }}
                // height={200}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

Club.defaultProps = {
  items: [],
  extraClasses: ''
}

export default Club
